/* About Me Style */
.aboutMe {
  height: 100vh;
  background-image: url('../Images/cerro-calafate.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

@media screen and (max-width: 400px) {
  .aboutMe {
    height: max-content;
    background-image: url('../Images/small-cerro-calafate.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
  }
}

.aboutMe-text {
  margin-left: 25%;
  padding-top: 50px;
  /* padding-bottom: 50px; */
  text-align: center;
  display: block;
  width: 50%;
}

.shown-aboutText {
  color: aliceblue;
  padding-top: 5%;
  padding-bottom: 20px;
  margin-left: 15%;
  margin-bottom: 0;
  font-size: 20px;
  text-align: center;
  line-height: 150%;
  width: 70%;
  transition: 2s;
}

.hidden-aboutText {
  font-size: 20px;
  left: -300%;
  line-height: 150%;
  transition: 2s;
  width: 70%;
}
