/* Home Style */

.home {
  background-image: url("https://media.giphy.com/media/3ohhwNqFMnb7wZgNnq/source.gif");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

@media only screen and (min-device-width: 400px) {
  .home {
    background-image: url("https://media.giphy.com/media/3ohhwNqFMnb7wZgNnq/source.gif");
    /* background-image: url("../Images/fitzRoy-B&W.jpg"); */
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
  }
}

.here {
  padding-top: 50px;
  margin-top: 0;
  text-align: center;
  transition: 2s;
  color: aliceblue;
}

.not-here {
  left:-100%;
  text-align: center;
}

.backend-list,
.frontend-list {
  width: 38%;
  padding-left: 0;
  margin: 8px;
  display: inline-table;
  position: relative;
  /* background-color: rgba(182, 132, 132, 0.5); */
}
/* Mobile Styling */
@media only screen and (max-device-width: 400px) {
  br {
    display: none;
  }
}

li {
  list-style: none;
  text-decoration: none;
  color: aliceblue;
}

.list-title {
  font-size: 16px;
  text-decoration: underline;
}

.Java {
  display: inline-flex;
  padding: 8px;
  height: 67px;
  width: 60px;
  background-image: url('../Images/pngfind.com-mysql-logo-transparent-png-744402.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 35px;
}

.Java:hover {
  background-size: 45px;
}

.MongoDB {
  display: inline-flex;
  padding: 8px;
  height: 67px;
  width: 60px;
  background-image: url('../Images/kisspng-mongodb-logo-database-nosql-postgresql-how-to-create-an-outstanding-tech-stack-clickup-bl-5c391bdfde6506.5513114315472465599109.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60px;
}

.MongoDB:hover {
  background-size: 70px;
}

.PostgresSQL {
  display: inline-flex;
  padding: 8px;
  height: 67px;
  width: 60px;
  background-image: url('../Images/pngwing.com.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60px;
}

.PostgresSQL:hover{
  background-size: 80px;
}

.JS {
  display: inline-flex;
  padding: 8px;
  height: 67px;
  width: 60px;
  background-image: url('../Images/kisspng-javascript-scalable-vector-graphics-logo-encapsula-javascript-le-ekran-grnts-almak-alpere-5b6dbeb4be0349.4316196815339189007783.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50px;
}

.JS:hover {
  background-size: 70px;
}

.Ruby {
  display: inline-flex;
  padding: 8px;
  height: 67px;
  width: 60px;
  background-image: url('../Images/PngItem_120179.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50px;
}

.Ruby:hover {
  background-size: 70px;
}

.HtmlCss {
  display: inline-flex;
  padding: 8px;
  height: 67px;
  width: 60px;
  background-image: url('../Images/Daco_4866189.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 65px;
}

.HtmlCss:hover{
  background-size: 85px;
}


/* Scroller Button - Disabled */

/* .scroller {
  position: relative;
  bottom: 0;
  text-align: center;
  margin: 10% 0;
}

.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.scroll-button {
  border-radius: 25%;
  text-decoration: none;
  padding: 10px;
  background-color: rgba(115, 196, 211, 0.5);
  color: black;
  font-weight: bold;
  padding: 8px 16px 8px 14px;
}


.scroll-button:hover {
  color: antiquewhite;
  padding: 8px 16px 8px 14px;
  margin: 0 0 0 2px;
} */
