html {
  margin: 0;
  padding:0;
  scroll-behavior:smooth;
}

body {
  color: darkgrey;
}

@media only screen and (min-device-width: 400px) {
  body {
  overflow: hidden;
  }
}


h1 {
  margin: 0;
}

h2 {
  margin: 0;
}

h3 {
  padding: 8px;
}

span {
  color: tomato;
  transition: 1s
}

span:hover {
  font-size: 25px;
  transition: 1s;
}

/* Clock Style */

.time{
  color: tomato;
  position: absolute;
  font-size: 12px;
  top: 0;
  right: 0;
  margin-right: 1em;
  text-align: center;
}
