/* Projects Style */

.projects-section {
  background-image: url('/src/Images/constellations.jpg');
  background-size: cover;
  height: 100vh;
  width: 100%;
}

.projects-title {
  text-align: center;
  margin: 0;
  padding-top: 50px;
  color: aliceblue;
}

.projects-list {
  width: 100%;
  margin-top: 5em;
}

@media screen and (max-width: 410px) {
  .projects-list {
    padding-left: 0;
  }
}


.project {
  display:inline-flex;
  flex-flow:row;
  width:100%;
}

.image-link {
  border: 3px double tomato;
  width: 150px;
  height: 100px;
  margin-bottom: 1em;
}

.project-name, .project-a {
  position: relative;
  height: max-content;
  margin: 0;
  padding: 0;
  /* display: inline-block; */
  width: 50%;
  text-decoration: none;
  color: aliceblue;
}

.project-name:hover {
  color: tomato;
}

.project-description {
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
  flex-wrap: wrap;
  word-wrap: break-word;
}

.name-description {
  width: 50%;
}

.tech {
  display: inline;
  color: black;
  width:fit-content;
  margin: 5px;
  padding: 3px;
  background-color: aliceblue;
  border-radius: 5%;
  opacity: 0.7;
}
