/* Navbar */

.nav-wrapper {
  z-index: 1;
  position: sticky;
  top:0;
  height: 0px;
  width: 100%;
}

.burger {
  color: tomato;
  display: inline-block;
  position: absolute;
  z-index: 10;
}

.menu-closed {
  display: inline-flex;
  position: absolute;
  left: -400%;
  transition-duration: 2s;
}

.menu-open {
  color: tomato;
  display: inline-flex;
  position: absolute;
  width: 30%;
  padding-left: 50px;
  height: 50px;
  margin: 8px;
  left: 0;
  transition: .5s;
}

@media only screen and (max-device-width: 400px) {
  .burger {
    display: none;
  }
}

.nav-a {
  text-decoration: none;
  color: tomato;
  font-weight: bold;
  padding: 8px 16px 8px 14px;
}

.nav-a:hover {
  color: antiquewhite;
  padding: 8px 16px 8px 14px;
  margin: 0 0 0 2px;
}

.nav-text {
  list-style: none;
  padding: 8px 0px 8px 16px;
}

.burger:hover {
  color: antiquewhite;
}