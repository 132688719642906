.contact {
  padding-top: 50px;
  height: 100vh;
  background-image: url('../Images/fitzRoy-B&W.jpg');
  background-size: cover;
  text-align: center;
}

@media only screen and (max-width: 400px) {
  .contact {
    height: 100vh;
    background-image: url('../Images/small-fitz-roy\ 2.jpg');
    background-color: darkgreen;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
  }
}

form {
  margin-top: 10%;
  width: 50%;
}

textarea {
  height: 120px;
}

h4 {
  margin-top: 60px;
  color: aliceblue;
}

input, textarea {
      margin-left: 60%;
      background: #1e242c;
      border: 0;
      box-sizing: border-box;
      color: #fff;
      display: block;
      font-size: 12pt;
      margin-bottom: 3px;
      outline: none;
      padding: 10px 15px;
      width: 80%;
}

.button {
    border:cornsilk;
    border-radius: 8px;
    padding: 10px 20px;
    margin-left: 50%;
    display: inline-block;
    font-size: 12px;
    font-family: 'Oxygen',sans-serif;
    letter-spacing: 2px;
}